<template>
    <div>
        <div class="class-body">
            <div :class="{
                'class-card':true,
                's-card':selectClass == item.key_name,
                'w-card':selectClass != item.key_name
            }" v-for="item in classList" :key="item.category" @click="getClassName(item.key_name)">
                <p>{{item.key_name}}</p>
            </div>
        </div>
        <adContent></adContent>
        <div class="game-list">
            <div class="game-card" v-for="item in gameList" :key="item.id" @click="goDetails(item.id)">
                <div class="card-img">
                    <img :src="item.logo" alt="">
                    <div class="play-btn">PLAY</div>
                </div>
                <div class="card-title">{{item.title}}</div>
                <div class="card-syn">{{item.synopsis}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import navMenu from '@/utils/navMenu'
import { searchGame, getClassList, getGameByKey, getGame } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent

        },
        data() {
            return {
                gameList: [],
                classList: [],
                selectClass: 'New'
            }
        },
        mounted() {
            eventBus.$on('searchgame',name=>{
                this.searchGame(name)
            })
            let name = this.$route.params.searchtext;
            if(name){
                this.searchGame(name);
            }else{
                this.getList();
            }
            this.getClassList();
        },
        methods: {
            async searchGame(name){
                let res = await searchGame({q:name});
                this.gameList = res;
            },
            async getList(){
                let res = await getGame();
                this.gameList = res;
            },
            async getClassList(){
                let classlist = await getClassList();
                classlist.forEach(el => {
                    navMenu.forEach(item=>{
                        if(item.name==el.key_name){
                            el.url = item.url
                        }
                    })
                });
                this.classList = classlist;
            },
            async getClassName(category){
                this.selectClass = category;
                let res = await getGameByKey({category});
                this.gameList = res;
            },
            goDetails(id){
                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.class-body{
    width: 100%;
    display: flex;
    // justify-content: space-between;
    margin-top: 54px;
    .class-card{
        width: 100px;
        height: 47px;
        line-height: 47px;
        border-radius: 10px 10px 0 0;
        text-align: center;
        p{
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 12px;
            color: #FFFFFF;
            font-style: normal;
            text-transform: none;
        }
    }
    .s-card{
        border-left: 1px solid #685AF5;
        border-top: 1px solid #685AF5;
        border-right: 1px solid #685AF5;
    }
    .w-card{
        border-bottom: 1px solid #685AF5;
    }
}
.game-list{
    margin-top: 42px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-gap: 20px;
    .game-card{
        width: 202px;
        background: #A19DFB;
        border-radius: 9px;
        padding: 10px 0 13px;
        box-sizing: border-box;
        .card-img{
            margin: 0 auto;
            width: 184px;
            height: 184px;
            border: 9px;
            position: relative;
            img{
                width: 184px;
                height: 184px;
            }
            .play-btn{
                background: #685AF5;
                width: 70px;
                height: 35px;
                line-height: 35px;
                border-radius: 5px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
        .card-title{
            width: 184px;
            height: 18px;
            margin: 13px auto 18px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .card-syn{
            width: 184px;
            margin: 0 auto;
            font-family: Inter, Inter;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            text-transform: none;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>